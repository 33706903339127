// app/javascript/controllers/confirmation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icsButton", "confirmButton"] // Pour cibler le bouton .ics

  connect() {
    console.log("Hello")
    console.log("targets", this.icsButtonTarget, this.confirmButtonTarget)
  }

  confirm(event) {
    console.log("confirm event launching")
    event.preventDefault(); // Empêche l'envoi immédiat du formulaire

    // Lancer les confettis
    this.launchConfetti();

    this.confirmButtonTarget.classList.add('fade-out');

    console.log("end of fade out")

    // Masquer le bouton "Confirmer" après l'animation (0.5s)
    setTimeout(() => {
      this.confirmButtonTarget.classList.add('d-none');
      // Afficher le bouton "Ajouter à mon agenda"
      this.icsButtonTarget.classList.remove('d-none');
      this.icsButtonTarget.classList.add('fade-in');
    }, 500);
  }

  launchConfetti() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }
}
