import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["firstName", "lastName", "kind", "otherInformation", "lastNameLabel"]

  connect() {
    console.log("Hello, Stimulus!", this.element)
    this.checkKindAndToggle()
  }

  checkKindAndToggle() {
    console.log("Checking kind", this.kindTarget.value)
    if (this.kindTarget.value === "individual") {
      console.log("Individual")
      this.firstNameTarget.classList.remove("d-none")
      this.lastNameTarget.classList.remove("d-none")
      this.lastNameLabelTarget.textContent = "Nom de famille"
      this.otherInformationTarget.classList.remove("d-none")
    } else if (this.kindTarget.value === "company") {
      this.firstNameTarget.classList.add("d-none")
      this.lastNameTarget.classList.remove("d-none")
      this.lastNameLabelTarget.textContent = "Dénomination sociale"
      this.otherInformationTarget.classList.remove("d-none")
    }
  }
}
