import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="session-timeout"
export default class extends Controller {
  static values = {
    interval: Number
  }

  connect() {
    this.startTimer()
  }

  disconnect() {
    this.stopTimer()
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.checkSession()
    }, this.intervalValue || 60000) // Par défaut, vérifie toutes les minutes
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  checkSession() {
    fetch('/users/check_session', {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.timedOut) {
        window.location.href = '/users/sign_in'
      }
    })
  }
}
